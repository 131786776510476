import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Customs Clearance
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Customs Clearance
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Customs Clearance</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/custom.webp"
              />
            </div>
            <div className="col-lg-12">
              <p>
                For businesses engaged in import and export activities,
                navigating the intricacies of customs clearance can often be a
                daunting and time-consuming process. At {companyname}, we
                prioritize seamless freight transportation, and thus, we offer
                reliable and efficient customs clearing services.
              </p>

              <p>
                Our expertise in handling the complex legal requirements and
                procedures involved in customs clearance sets {companyname} apart. Our team of professionals is well-equipped to
                handle all types of shipments, whether they are transported by
                air, sea, or land.
              </p>

              <p>
                Our unwavering commitment to delivering unparalleled customer
                service is evident in our customs clearing services, which we
                hold in high regard. We offer a comprehensive array of custom
                clearing services, including cargo inspection, document
                preparation, import and export procedures, and customs
                documentation. With our assistance, businesses can have
                confidence that their shipments will smoothly clear customs
                without delays or complications.
              </p>

              <p>
                Recognizing that each organization has unique demands, {companyname} provides specialized solutions tailored to meet
                their specific needs. Our team of experts collaborates closely
                with clients to gain a deep understanding of their distinct
                requirements, enabling us to offer customized solutions that
                expedite the customs clearance process.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

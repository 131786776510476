import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Road Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Road Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Road Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/road.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
              {companyname} is dedicated to providing standardized
                surface transportation services with the utmost confidence in
                the safe handling of cargo. Our primary objective is to deliver
                goods in impeccable condition, ensuring complete client
                satisfaction. We uphold the highest levels of integrity and
                commitment, resulting in services that are both effective and
                trustworthy.
              </p>

              <p>
                Our road transport services have earned high regard from clients
                who consistently appreciate our swift responsiveness to their
                needs. When it comes to transporting items from one location to
                another, we exclusively employ top-tier vehicles, positioning us
                as a premier provider of road transport services in India.
              </p>

              <p>
                Our land transport service standards are thoughtfully aligned
                with the growing demands of businesses and industries. We
                prioritize our clients' security by offering precisely defined
                transit times, enabling them to effectively plan their finances
                and maintain precise control over their goods' flow.
              </p>

              <p>
                Specializing in the secure movement of our clients' goods, our
                fleet of container trailers ensures complete safety. Our prompt
                and reliable services are built on the principles of care and
                excellence. Our business ethos is centered on delivering goods
                in immaculate condition. Setting us apart, we take pride in our
                customer-centric approach, where on-time delivery and safety are
                paramount. We have consistently provided clients with
                dependable, efficient, and expert services, reflecting our
                unwavering commitment to their satisfaction.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };
 


  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <div
        className="container newsletter mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="row justify-content-center">
          <div className="col-lg-10 border rounded p-1">
            <div className="border rounded text-center p-1">
              <div className="bg-white rounded text-center p-5">
                <h4 className="mb-4">
                  Subscribe Our{" "}
                  <span className="text-primary text-uppercase">
                    Newsletter
                  </span>
                </h4>
                <div
                  className="position-relative mx-auto"
                  style={{ maxWidth: "400px" }}
                ><form
                action="/php/thankyou-subscribe.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                  <input  onChange={(event) => handleSubemailChange(event)}
                  id="subemail" value={subemail}
                    className="form-control w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Enter your email"
                  /> 
                  <button
                    type="submit"
                    className="btn btn-primary py-2 px-3 position-absolute top-0 end-0 mt-2 me-2"
                  >
                    Submit
                  </button></form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-light footer wow fadeIn" data-wow-delay="0.1s">
          <div className="container pb-5">
            <div className="row g-5">
              <div className="col-md-6 col-lg-4">
                <div className="bg-primary rounded p-4">
                  <Link to="index.html"><h1 className="text-white text-uppercase mb-3">{companyname}</h1></Link>
                  <p className="text-white mb-0">
                  Blue Oasis Enterprises is guided by a steadfast belief that achieving success in the ever-evolving business landscape is the result of fostering competitiveness and maintaining unwavering standards of quality. We understand that in a world driven by dynamic market forces, offering a seamless blend of both attributes is essential. 
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <h6 className="section-title text-start text-primary text-uppercase mb-4">Contact</h6>
                <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
                <p className="mb-2"><i className="fa fa-phone-alt me-3" />{companynumber}</p>
                <p className="mb-2"><i className="fa fa-envelope me-3" />{companyemail}</p>
                
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="row gy-5 g-4">
                  <div className="col-md-6">
                    <h6 className="section-title text-start text-primary text-uppercase mb-4">Company</h6>
                    <Link className="btn btn-link" to="/Home">Home</Link>
          <Link className="btn btn-link" to="/About">About</Link>
          <Link className="btn btn-link" to="/Services">Services</Link>
          <Link className="btn btn-link" to="/Trading">Trading Solutions</Link>
          <Link className="btn btn-link" to="/Contact">Contact</Link>
          <Link className="btn btn-link" to="/Getquote">Getquote</Link>
                  </div>
                  <div className="col-md-6">
                    <h6 className="section-title text-start text-primary text-uppercase mb-4">Services</h6>
                    <Link to="/Ocean" className="btn btn-link">Ocean Freight</Link>
                      <Link to="/Air" className="btn btn-link">Air Freight</Link>
                      <Link to="/Road" className="btn btn-link">Road Freight</Link>
                      <Link to="/Rail" className="btn btn-link">Rail Freight</Link>
                      <Link to="/Customs" className="btn btn-link">Customs Clearance</Link>
                      <Link to="/Warehousing" className="btn btn-link">Warehousing Service</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="copyright">
              <div className="row">
                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                  ©2023 <Link className="border-bottom" to="/">{companyname}</Link>, All Right Reserved. 
                </div>
                <div className="col-md-6 text-center text-md-end">
                  <div className="footer-menu">
                  <Link to="/Privacy">Privacy Policy</Link>
                  <Link to="/Terms">Terms & Conditions</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        {/* Back to Top */}
        <Link to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></Link>
    </>
  );
};

export default Footer;

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Air Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Air Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Air Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/air.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                {companyname} is a well-established name in the realm of air
                freight forwarding, specializing in the meticulous handling of
                perishable goods and general cargo. Our reputation is built on
                delivering efficient and reliable air freight services, with a
                remarkable daily cargo handling capacity from India to numerous
                destinations worldwide. With an extensive network, we gain
                valuable insights into the challenges that businesses encounter
                in the realm of air freight transportation.
              </p>

              <p>
                What sets us apart is our expertise in managing
                frozen-temperature-controlled shipments, encompassing a diverse
                range of items such as pharmaceuticals, vaccines, bulk drugs,
                fresh produce, delicate flowers, electronics, garments, and
                tissue culture. Recognizing the critical importance of handling
                such shipments with precision and care, we've curated a team of
                professionals adept at managing all kinds of perishable cargo.
              </p>

              <p>
                At {companyname}, we understand that a one-size-fits-all
                approach doesn't suffice in the world of air freight. We hold
                the belief that each client is unique, which is why we offer
                tailor-made solutions that align with the specific needs of each
                individual client. Our enthusiastic team of specialists is
                committed to crafting innovative and adaptable air freight
                solutions that set new benchmarks in the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

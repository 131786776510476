import { useState } from "react";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

export default function Getquote() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");
  const [uorigin, setUorigin] = useState("");
  const [udestination, setUdestination] = useState("");
  const [ugoods, setUgoods] = useState("");
  const [umeasurements, setUmeasurements] = useState("");
  const [ulength, setulength] = useState("");
  const [uheight, setUheight] = useState("");
  const [uwidth, setUwidth] = useState("");
  const [uweight, setUweight] = useState("");
  const [udate, setUdate] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };
  const handleuoriginChange = (e) => {
    setUorigin(e.target.value);
  };

  const handleudestinationChange = (e) => {
    setUdestination(e.target.value);
  };
  const handlugoodsChange = (e) => {
    setUgoods(e.target.value);
  };
  const handleumeasurementsChange = (e) => {
    setUmeasurements(e.target.value);
  };
  const handleulengthChange = (e) => {
    setulength(e.target.value);
  };
  const handleuheightChange = (e) => {
    setUheight(e.target.value);
  };
  const handleuwidthChange = (e) => {
    setUwidth(e.target.value);
  };
  const handleuweightChange = (e) => {
    setUweight(e.target.value);
  };
  const handleudateChange = (e) => {
    setUdate(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Header />

      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Request For Quote
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Request For Quote
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {/* Page Header End */}

      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Request For Quote
            </h6>
            <h3>Efficiency in Every Step, Excellence in Every Move</h3>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="wow fadeInUp" data-wow-delay="0.2s">
                <form
                  action="/php/thankyou-getquote.php"
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row">
                   

                    <div className="col-lg-6">
                      {" "}
                      <h4>Personal Information</h4>
                    </div>
                    <div className="col-md-12">
                      <div className="styled-input">
                        <input
                          className="form-control border-0 bg-light"
                          value={uname}
                          onChange={(event) => handleUnameChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="text"
                          name="uname"
                          id="uname"
                          placeholder="Enter your Fullname"
                          required="Please Enter the Fullname"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="styled-input">
                        <input
                          className="form-control border-0 bg-light"
                          value={uemail}
                          onChange={(event) => handleUemailChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="email"
                          name="uemail"
                          id="uemail"
                          placeholder="Enter Your Email"
                          required="Please Enter Your Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="styled-input">
                        <input
                          className="form-control border-0 bg-light"
                          value={uphone}
                          onChange={(event) => handleUphoneChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="text"
                          name="uphone"
                          id="uphone"
                          placeholder="Enter Phone Number"
                          required="Please Enter Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="styled-input">
                        <input
                          className="form-control border-0 bg-light"
                          value={subject}
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12" style={{ padding: "10px" }}>
                      <select
                        className="form-control border-0 bg-light"
                        name="uservice"
                        id="uservice"
                        value={uservice}
                        onChange={(event) => handleuserviceChange(event)}
                        style={{
                          outline: "none",
                          color: "#333",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          letterSpacing: "2px",
                          width: "100%",
                          border: "1px solid #ddd",
                          textAlign: "left",
                          background: "#F9FAFF",
                        }}
                      >
                        <option selected>Service Required</option>
                        <option value="Air Freight Forwarding">
                          Air Freight Forwarding
                        </option>
                        <option value="Ocean Freight Forwarding">
                          Ocean Freight Forwarding
                        </option>
                        <option value="Project Cargo">Project Cargo</option>
                      </select>
                    </div>
                    <div className="styled-input col-lg-4">
                      <input
                        className="form-control border-0 bg-light"
                        value={uorigin}
                        onChange={(event) => handleuoriginChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uorigin"
                        id="uorigin"
                        placeholder="Origin City"
                      />
                    </div>
                    <div className="styled-input col-lg-4">
                      <input
                        className="form-control border-0 bg-light"
                        value={udestination}
                        onChange={(event) => handleudestinationChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="udestination"
                        id="udestination"
                        placeholder="Destination City"
                      />
                    </div>
                    <div className="styled-input col-lg-4">
                      <input
                        className="form-control border-0 bg-light"
                        value={ugoods}
                        onChange={(event) => handlugoodsChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="ugoods"
                        id="ugoods"
                        placeholder="Types Of Goods"
                      />
                    </div>

                    <div className="col-12" style={{ padding: "10px" }}>
                      <select
                        className="form-control border-0 bg-light"
                        value={umeasurements}
                        onChange={(event) => handleumeasurementsChange(event)}
                        name="umeasurements"
                        id="umeasurements"
                        style={{
                          outline: "none",
                          color: "#333",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          letterSpacing: "2px",
                          width: "100%",
                          border: "1px solid #ddd",
                          textAlign: "left",
                          background: "#F9FAFF",
                        }}
                      >
                        <option selected>Measurements Below</option>
                        <option value="Metric(Kgs/cms)">Metric(Kgs/cms)</option>
                        <option value="Imperial(lbs/in)">
                          Imperial(lbs/in)
                        </option>
                      </select>
                    </div>
                    <div className="styled-input col-lg-6">
                      <input
                        className="form-control border-0 bg-light"
                        value={ulength}
                        onChange={(event) => handleulengthChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="ulength"
                        id="ulength"
                        placeholder="Length"
                      />
                    </div>
                    <div className="styled-input col-lg-6">
                      <input
                        className="form-control border-0 bg-light"
                        value={uheight}
                        onChange={(event) => handleuheightChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uheight"
                        id="uheight"
                        placeholder="Height"
                      />
                    </div>
                    <div className="styled-input col-lg-6">
                      <input
                        className="form-control border-0 bg-light"
                        value={uwidth}
                        onChange={(event) => handleuwidthChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uwidth"
                        id="uwidth"
                        placeholder="Width"
                      />
                    </div>
                    <div className="styled-input col-lg-6">
                      <input
                        className="form-control border-0 bg-light"
                        value={uweight}
                        onChange={(event) => handleuweightChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uweight"
                        id="uweight"
                        placeholder="weight"
                      />
                    </div>
                    <div className="col-12 " style={{ padding: "10px" }}>
                      <label>Date Required at destination</label>
                      <div
                        className="date"
                        id="date"
                        data-target-input="nearest"
                      >
                        <input
                          value={udate}
                          onChange={(event) => handleudateChange(event)}
                          type="date"
                          className="form-control border-0 bg-light"
                          name="udate"
                          id="udate"
                          placeholder="Date required at destination"
                          style={{ height: "55px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="styled-input">
                        <textarea
                          className="form-control border-0 bg-light"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={umessage}
                          onChange={(event) => handlemessageChange(event)}
                          name="umessage"
                          id="umessage"
                          placeholder="Write Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="submitquote"
                      defaultValue="SEND"
                      className="btn btn-primary w-100 py-3"
                    >
                      Send Request
                    </button>
                  </div>
                  <br />
                </form>
                <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Ocean Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Ocean Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Ocean Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/ocean.jpg"
              />
            </div>
            <div className="col-lg-12">
              <p>
                As a leading figure in the ocean freight sector, {companyname} operates with prominence in major seaports. Our
                specialization lies in delivering unique maritime freight
                solutions that contribute to our clients' financial success.
                Leveraging our robust agency network and skilled workforce, we
                provide seamless movement and tailored solutions that cater to
                the distinct needs of each client.
              </p>

              <p>
                Our standout ability in handling breakbulk, over-dimensional,
                and heavy lift cargoes is a cornerstone of our capabilities.
                Recognizing the uniqueness of every shipment, we offer
                specialized solutions to ensure the secure and prompt delivery
                of your cargo. Our experienced team is equipped to manage any
                challenges that may arise during the shipping process.
              </p>

              <p>
                With a comprehensive agency network encompassing key seaports
                worldwide, we are able to offer an array of dependable,
                flexible, and cost-effective ocean freight services. Our
                partnerships with leading carriers and shipping lines empower us
                to manage all types of cargo, including full-container-load
                (FCL), less-than-container-load (LCL), and breakbulk shipments.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Trading Solutions
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Trading Solutions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Fashion</h1>
              <p>
                The fashion industry is characterized by rapidly evolving trends
                and ever-shortening product life cycles. At our core, we
                recognize the critical importance of keeping pace with this
                swift rhythm. As the landscape of fashion constantly reshapes
                itself, we stand ready to meet its demands head-on. We
                understand that success in this industry hinges on the pillars
                of speed, quality, flexibility, and the ability to seamlessly
                adapt to change.
              </p>
              <p>
                Our approach to fashion logistics is not just a mere adjustment
                – it's a complete transformation. We have realigned our
                resources to align with the unique demands of the fashion
                sector. At every step of the journey, from sourcing to
                distribution, we have fine-tuned our operations to cater to the
                dynamic nature of fashion. Our agility isn't just a response;
                it's a proactive strategy to ensure that our clients are always
                ahead of the curve.
              </p>
            </div>

            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/fashion.jpg"
              />
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/food.png"
              />
            </div>
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Food</h1>
              <p>
                The fashion industry operates in a realm where the life cycles
                of products are constantly shrinking year after year. We
                recognize the intrinsic nature of this phenomenon, where trends
                evolve swiftly and consumer preferences shift rapidly. In
                response to these dynamics, we have not only stayed attuned but
                also revolutionized our approach to accommodate the industry's
                demands. Our awareness of the essential factors - speed,
                quality, flexibility, and the capacity to embrace change -
                underscores our commitment to fashion logistics.
              </p>
              <p>
                Our resolute commitment to the fashion sector has prompted us to
                reshape our resources entirely. We understand that success in
                this industry necessitates not only reacting to market trends
                but anticipating them. By aligning our operations with the
                industry's pace, we have forged an operational model that
                thrives on agility. We have evolved from being just a logistics
                provider to becoming an essential link in your supply chain,
                enabling you to keep up with the relentless cadence of the
                fashion world.
              </p>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Chemical</h1>
              <p>
                Within the realm of specialized chemicals, we boast a dedicated
                team equipped with a wealth of expertise in handling chemical
                products. Our team is fortified with continuous training in the
                intricacies of dangerous goods and transport regulations,
                ensuring that your company's needs for a secure and hazard-free
                supply chain are met comprehensively.
              </p>
              <p>
                Dealing with hazardous materials demands an unparalleled level
                of precision and caution. Our specialized chemicals team is
                well-versed in the nuances of transporting dangerous goods.
                Whether it's volatile substances or materials with specific
                handling requirements, we ensure that every aspect of the
                transportation process adheres to the highest safety standards.
              </p>
            </div>

            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/chemical.jpeg"
              />
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/furniture.jpg"
              />
            </div>
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Furniture</h1>
              <p>
                Furniture manufacturing, importing, and retailing are diverse
                fields, each with their unique characteristics. However, a
                common thread that unites them is the presence of logistics
                challenges. Across the industry, transportation complexities
                arise, impacting the smooth flow of operations. From production
                delays to delivery schedule disruptions, even the most efficient
                companies encounter hurdles. Additionally, the global reach
                capabilities often fall short for furniture businesses,
                regardless of their size.
              </p>
              <p>
                At {companyname}, addressing these challenges is part of our
                daily routine. This is particularly true in the furniture
                market, where we've accumulated three decades of combined
                experience catering to the industry's unique demands. Over the
                years, our commitment to delivering positive outcomes has earned
                us a solid reputation in the furniture sector.
              </p>
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Oil and Gas</h1>
              <p>
                In the realm of Oil & Gas supply chains, safety takes on
                paramount importance. At {companyname}, we recognize the criticality of
                this factor and stand ready to provide comprehensive assistance
                throughout the entire process. Our services are meticulously
                tailored to address the unique needs of the industry. From the
                secure and efficient transportation of oil & gas products to the
                intricate coordination of international projects, we offer
                comprehensive solutions that span the globe.
              </p>
              <p>
                Our team is equipped with technical expertise that remains at
                your service at all times. With a dedicated group of
                professionals, we are well-equipped to oversee the entire supply
                chain. Our ultimate objective is to align with your specific
                requirements, offering unwavering support across every facet of
                your operations.
              </p>
            </div>

            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/oil.jpg"
              />
            </div>
          </div>
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/retail.jpeg"
              />
            </div>
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                Trading Services
              </h6>
              <h1 className="mb-4">Retail</h1>
              <p>
                Collaboration is at the heart of our approach. We engage closely
                with your vendors, actively monitoring production timelines and
                ensuring timely availability. Our focus is on facilitating a
                swift and seamless turnover, starting from the manufacturer's
                doorstep and culminating at the port.
              </p>
              <p>
                Together with our clients, we cultivate ongoing value by setting
                up and monitoring key performance indicators. We believe in the
                power of data-driven insights to optimize operations. Periodic
                customer business reviews allow us to assess progress and make
                strategic adjustments as needed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

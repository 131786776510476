import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <div
        className="container-fluid p-0 mb-5 w3-banner jarallax"
        style={{ height: "700px" }}
      >
        <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" style={{ height: "700px" }}>
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "700px" }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
                    {companyname}
                  </h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">
                    Trade Empowered, Futures Shaped
                  </h1>
                  <Link
                    to="/About"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    About Us
                  </Link>
                  <Link
                    to="/Contact"
                    className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="carousel-item" style={{ height: "700px" }}>
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ maxWidth: "700px" }}>
                  <h6 className="section-title text-white text-uppercase mb-3 animated slideInDown">
                    Welcome to {companyname}
                  </h6>
                  <h1 className="display-3 text-white mb-4 animated slideInDown">
                    Beyond Shipping, Beyond Expectations
                  </h1>
                  <Link
                    to="/Services"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Explore Services
                  </Link>
                  <Link
                    to="/Getquote"
                    className="btn btn-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Request Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to{" "}
                <span className="text-primary text-uppercase">
                  {companyname}
                </span>
              </h1>
              <p>
                {companyname} is guided by a steadfast belief that achieving
                success in the ever-evolving business landscape is the result of
                fostering competitiveness and maintaining unwavering standards
                of quality. We understand that in a world driven by dynamic
                market forces, offering a seamless blend of both attributes is
                essential. Our commitment goes beyond mere words, as we
                tirelessly strive to deliver services that reflect
                cost-effectiveness without compromising on the high-quality
                standards that define our approach.
              </p>

              <p>
                With a dedicated focus on meeting the diverse needs of our
                customers, we have meticulously designed our range of services.
                At {companyname}, we recognize that each client comes with
                unique requirements, and our objective is to provide tailor-made
                solutions that not only meet these needs but also exceed
                expectations. From efficient Freight Forwarding services to
                comprehensive trading solutions, we aim to be the bridge that
                connects businesses to their goals.
              </p>
              <div className="row g-3 pb-4">
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users fa-2x text-primary mb-2" />
                      <p className="mb-0">Team Work</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-handshake fa-2x text-primary mb-2" />
                      <p className="mb-0">Flexibilty</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-lightbulb fa-2x text-primary mb-2" />
                      <p className="mb-0">Innovation</p>
                    </div>
                  </div>
                </div>
              </div>
              <Link className="btn btn-primary py-3 px-5 mt-2" to="/About">
                Learn More
              </Link>
            </div>
            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src="img/about-1.jpg"
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="img/about-2.jpg"
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-50 wow zoomIn"
                    data-wow-delay="0.5s"
                    src="img/about-3.jpg"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src="img/about-4.jpg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Room Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Services
            </h6>
            <h1 className="mb-5">
              {" "}
              Our{" "}
              <span className="text-primary text-uppercase">
                Freight Forwarding Services
              </span>
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/ocean.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Ocean Freight</h5>
                  </div>

                  <p className="text-body mb-3">As a leading figure in the ocean freight sector, {companyname} operates with prominence in major seaports. Our
                specialization lies in delivering unique..</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Ocean"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/air.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Air Freight</h5>
                  </div>

                  <p className="text-body mb-3">{companyname} is a well-established name in the realm of air
                freight forwarding, specializing in the meticulous handling of
                perishable goods and general cargo.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Air"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/road.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Road Freight</h5>
                  </div>

                  <p className="text-body mb-3"> {companyname} is dedicated to providing standardized
                surface transportation services with the utmost confidence in
                the safe handling of cargo.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Road"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/rail.webp"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Rail Freight</h5>
                  </div>

                  <p className="text-body mb-3"> {companyname}, a prominent freight forwarding company, is
                dedicated to delivering tailored logistics solutions to
                businesses of all sizes. </p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Rail"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/custom.webp"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Customs Clearance</h5>
                  </div>

                  <p className="text-body mb-3"> For businesses engaged in import and export activities,
                navigating the intricacies of customs clearance can often be a
                daunting and time-consuming process.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Customs"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/warehousing.png"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Warehousing Service</h5>
                  </div>

                  <p className="text-body mb-3"> In today's fast-paced business environment, especially for a
                large organization like yours, managing the balance between
                items still in the...</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Warehousing"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Room End */}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Services
            </h6>
            <h1 className="mb-5">
              Explore Our{" "}
              <span className="text-primary text-uppercase">Trading Services</span>
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/fashion.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Fashion</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/food.png" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Food</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/chemical.jpeg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Chemical</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/furniture.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Furniture</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/oil.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Oil and Gas</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/retail.jpeg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Retail</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
           
           
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}

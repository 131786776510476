import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Warehousing
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Warehousing
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Warehousing</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/warehousing.png"
              />
            </div>
            <div className="col-lg-12">
              <p>
                In today's fast-paced business environment, especially for a
                large organization like yours, managing the balance between
                items still in the production stage and available storage space
                can be a complex challenge. At {companyname}, we offer a
                range of solutions that can help optimize your operations by
                efficiently managing your inventory through well-organized
                warehousing facilities.
              </p>

              <p>
                Our comprehensive services encompass a variety of options. On
                one hand, we provide intracity and intercity transportation
                services, ensuring seamless movement of goods within and between
                cities. On the other hand, our expertise extends to warehouse
                services and domestic courier solutions, offering you a
                comprehensive logistics solution under one roof.
              </p>

              <p>
                The cornerstone and central pillar of our logistics management
                system lies in our warehouse management capabilities. {companyname} excels as a well-known warehousing provider,
                catering to both import and export shipments. Through our
                extensive network of partners, we offer specialized solutions
                designed to accommodate temperature-controlled goods, ensuring
                the integrity of perishable items.
              </p>

              <p>
                We go beyond just storage. Our comprehensive warehousing
                services include repackaging and palletizing shipments, as well
                as meticulous inventory management and control. This meticulous
                attention to detail ensures that your products are
                well-preserved and easily accessible when needed.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              About Us
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  About Us
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary text-uppercase">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to{" "}
                <span className="text-primary text-uppercase">
                  {companyname}
                </span>
              </h1>
              <p>
                {companyname} is guided by a steadfast belief that achieving
                success in the ever-evolving business landscape is the result of
                fostering competitiveness and maintaining unwavering standards
                of quality. We understand that in a world driven by dynamic
                market forces, offering a seamless blend of both attributes is
                essential. Our commitment goes beyond mere words, as we
                tirelessly strive to deliver services that reflect
                cost-effectiveness without compromising on the high-quality
                standards that define our approach.
              </p>
              <div className="row g-3 pb-4">
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users fa-2x text-primary mb-2" />
                      <p className="mb-0">Team Work</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-handshake fa-2x text-primary mb-2" />
                      <p className="mb-0">Flexibilty</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-lightbulb fa-2x text-primary mb-2" />
                      <p className="mb-0">Innovation</p>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                With a dedicated focus on meeting the diverse needs of our
                customers, we have meticulously designed our range of services.
                At {companyname}, we recognize that each client comes with
                unique requirements, and our objective is to provide tailor-made
                solutions that not only meet these needs but also exceed
                expectations. From efficient Freight Forwarding services to
                comprehensive trading solutions, we aim to be the bridge that
                connects businesses to their goals.
              </p>
            </div>

            <div className="col-lg-6">
              <div className="row g-3">
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.1s"
                    src="img/about-1.jpg"
                    style={{ marginTop: "25%" }}
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-100 wow zoomIn"
                    data-wow-delay="0.3s"
                    src="img/about-2.jpg"
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid rounded w-50 wow zoomIn"
                    data-wow-delay="0.5s"
                    src="img/about-3.jpg"
                  />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid rounded w-75 wow zoomIn"
                    data-wow-delay="0.7s"
                    src="img/about-4.jpg"
                  />
                </div>
              </div>
            </div>

            <p>
              Our dedication to providing exceptional services is not just a
              statement; it's our driving force. Our team is comprised of
              experts who bring a wealth of industry experience, ensuring that
              our clients benefit from the highest level of expertise. The
              backbone of our operations is a commitment to transparency,
              reliability, and customer satisfaction. In an ever-connected world
              where businesses need to navigate complexities, {companyname}{" "}
              stands as a reliable partner, offering solutions that resonate
              with excellence, efficiency, and effectiveness.
            </p>
          </div>
        </div>
      </div>
      {/* About End */}
      <Footer />
    </>
  );
}

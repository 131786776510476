import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Air Freight
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>

                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Rail Freight
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-12 text-center">
              <h6 className="section-title text-start text-primary text-uppercase">
                Freight Forwarding Services
              </h6>
              <h1 className="mb-4">Rail Freight</h1>
              <img
                className="img-fluid rounded  wow zoomIn"
                data-wow-delay="0.1s"
                src="img/rail.webp"
              />
            </div>
            <div className="col-lg-12">
              <p>
              {companyname}, a prominent freight forwarding company, is
                dedicated to delivering tailored logistics solutions to
                businesses of all sizes. In addition to our renowned air and
                ocean freight services, we extend our offerings to encompass
                rail transport options, allowing us to comprehensively meet the
                diverse demands of our clients.
              </p>

              <p>
                Rail transportation holds significant importance in India as a
                crucial method of cargo movement. It stands as an economical and
                environmentally conscious solution for transporting large
                quantities of goods over extensive distances. The committed team
                of experts at {companyname} possesses a wealth of
                expertise in rail transportation. Our comprehensive solutions
                span from door-to-door delivery, efficient handling, and secure
                storage of items to the intricate processes of customs clearance
                and documentation.
              </p>

              <p>
                Our extensive rail transportation network interconnects India's
                major cities and industrial centers, efficiently managed by Blue
                Sun Enterprises. Our array of rail freight services encompasses
                cross-border operations, PTL and TL freight, and reliable parcel
                delivery. Irrespective of shipment size or complexity, our rail
                transportation solutions are tailored to cater to the specific
                needs of each client, ensuring seamless and efficient cargo
                movement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

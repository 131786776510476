import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5">
          <div className="container text-center pb-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
            Services
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center text-uppercase">
                <li className="breadcrumb-item">
                  <Link to="/Home">Home</Link>
                </li>
           
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Services
            </h6>
            <h1 className="mb-5">
              {" "}
              Our{" "}
              <span className="text-primary text-uppercase">
                Freight Forwarding Services
              </span>
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/ocean.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Ocean Freight</h5>
                  </div>

                  <p className="text-body mb-3">As a leading figure in the ocean freight sector, {companyname} operates with prominence in major seaports. Our
                specialization lies in delivering unique..</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Ocean"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/air.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Air Freight</h5>
                  </div>

                  <p className="text-body mb-3">{companyname} is a well-established name in the realm of air
                freight forwarding, specializing in the meticulous handling of
                perishable goods and general cargo.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Air"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/road.jpg"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Road Freight</h5>
                  </div>

                  <p className="text-body mb-3"> {companyname} is dedicated to providing standardized
                surface transportation services with the utmost confidence in
                the safe handling of cargo.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Road"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/rail.webp"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Rail Freight</h5>
                  </div>

                  <p className="text-body mb-3"> {companyname}, a prominent freight forwarding company, is
                dedicated to delivering tailored logistics solutions to
                businesses of all sizes. </p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Rail"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/custom.webp"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Customs Clearance</h5>
                  </div>

                  <p className="text-body mb-3"> For businesses engaged in import and export activities,
                navigating the intricacies of customs clearance can often be a
                daunting and time-consuming process.</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Customs"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="room-item shadow rounded overflow-hidden">
                <div className="position-relative">
                  <img
                    className="img-fluid"
                    src="img/warehousing.png"
                    style={{ height: "210px" }}
                    alt=""
                  />
                  <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
                    Services
                  </small>
                </div>
                <div className="p-4 mt-2">
                  <div className="d-flex justify-content-between mb-3">
                    <h5 className="mb-0">Warehousing Service</h5>
                  </div>

                  <p className="text-body mb-3"> In today's fast-paced business environment, especially for a
                large organization like yours, managing the balance between
                items still in the...</p>
                  <div className="d-flex justify-content-between">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Warehousing"
                    >
                      Learn More
                    </Link>
                    <Link
                      className="btn btn-sm btn-dark rounded py-2 px-4"
                      to="/Getquote"
                    >
                      Request Quote
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Room End */}

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary text-uppercase">
              Our Services
            </h6>
            <h1 className="mb-5">
              Explore Our{" "}
              <span className="text-primary text-uppercase">Trading Services</span>
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/fashion.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Fashion</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/food.png" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Food</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/chemical.jpeg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Chemical</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/furniture.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Furniture</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/oil.jpg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Oil and Gas</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="rounded shadow overflow-hidden">
                <div className="position-relative">
                  <img className="img-fluid" src="img/retail.jpeg" alt="" style={{height:"240px"}} />
                  <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                    <Link
                      className="btn btn-sm btn-primary rounded py-2 px-4"
                      to="/Trading"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 mt-3">
                  <h5 className="fw-bold mb-0">Retail</h5>
                  <small>Trading Service</small>
                </div>
              </div>
            </div>
           
           
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}

import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
 <div className="container-fluid bg-dark px-0">
        <div className="row gx-0">
          <div className="col-lg-3 bg-dark d-none d-lg-block">
            <Link to="/Home" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
              <img src="/img/logo.png" style={{height:"100px"}}></img>
            </Link>
          </div>
          <div className="col-lg-9">
            <div className="row gx-0 bg-white d-none d-lg-flex">
              <div className="col-lg-7 px-5 text-start">
                <div className="h-100 d-inline-flex align-items-center py-2 me-4">
                  <i className="fa fa-envelope text-primary me-2" />
                  <p className="mb-0">{companyemail}</p>
                </div>
                <div className="h-100 d-inline-flex align-items-center py-2">
                  <i className="fa fa-phone-alt text-primary me-2" />
                  <p className="mb-0">{companynumber}</p>
                </div>
              </div>
              <div className="col-lg-5 px-5 text-end">
                <div className="d-inline-flex align-items-center py-2">
                  <Link className="me-3" to="/Privacy">Privacy Policy</Link>
                  <Link className="me-3" to="/Terms">Terms & Conditions</Link>
                  
                </div>
              </div>
            </div>
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark p-3 p-lg-0">
              <Link to="/Home" className="navbar-brand d-block d-lg-none">
              <img src="/img/logo.png" style={{height:"100px"}}></img>
              </Link>
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div className="navbar-nav mr-auto py-0">
                  <Link to="/Home" className="nav-item nav-link">Home</Link>
                  <Link to="/About" className="nav-item nav-link">Who We Are</Link>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Freight Forwarding</Link>
                    <div className="dropdown-menu rounded-0 m-0">
                      <Link to="/Ocean" className="dropdown-item">Ocean Freight</Link>
                      <Link to="/Air" className="dropdown-item">Air Freight</Link>
                      <Link to="/Road" className="dropdown-item">Road Freight</Link>
                      <Link to="/Rail" className="dropdown-item">Rail Freight</Link>
                      <Link to="/Customs" className="dropdown-item">Customs Clearance</Link>
                      <Link to="/Warehousing" className="dropdown-item">Warehousing Service</Link>
                      
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Trading Solutions</Link>
                    <div className="dropdown-menu rounded-0 m-0">
                      <Link to="/Trading" className="dropdown-item">Fashion</Link>
                      <Link to="/Trading" className="dropdown-item">Food</Link>
                      <Link to="/Trading" className="dropdown-item">Chemical </Link>
                      <Link to="/Trading" className="dropdown-item">Furniture</Link>
                      <Link to="/Trading" className="dropdown-item">Oil and Gas</Link>
                      <Link to="/Trading" className="dropdown-item">Retail</Link>
                    
                      
                    </div>
                  </div>
                  <Link to="/Contact" className="nav-item nav-link">Contact</Link>
                </div>
                <Link to="/Getquote" className="btn btn-primary rounded-0 py-4 px-md-5 d-none d-lg-block">Request For Quote<i className="fa fa-arrow-right ms-3" /></Link>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
